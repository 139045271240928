import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import moment from 'moment'
import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { AuthContext } from '../AuthContext/AuthContext'
import coupon from '../image/coupon.webp'
import ok from '../image/iccheck.png'


const user_id = localStorage.getItem("user_id")
const Coupon = () => {
    const [couponlist, setCouponlist] = useState([])
    const [date, setDate] = useState("")
    const [open, setOpen] = React.useState(false);

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration: 0
        })
    }, [location.pathname])

    const { setApplyCoupon } = useContext(AuthContext)

    const navigate = useNavigate()
    const applyCoupon = (coupon) => {
        navigate('/checkout')
        setApplyCoupon(coupon)

    }
    useEffect(() => {
        axios.post("https://apiwowbeleafadapiapp.be-leaf.in/API/Get_Data",
            {
                "START": "0",
                "END": "100000",
                "WORD": "",
                "GET_DATA": "Get_CoupenCodeList",
                "ID1": `${user_id}`,
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "ALL",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": ""
            }
        ).then((res) => {
            console.log(res.data.DATA);
            setCouponlist(res.data.DATA)
            const new1 = res.data.DATA
            const new2 = new1[0].REG_DATE
            console.log(new2);
            setDate(Date(new2))


        })
    }, [])

    const style1 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };


    const handleOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <div className="container">
                <div className="coupon">
                <div className="row ">
                    <div className="col-md-12 col-12">
                        {couponlist.length === 0 && <h3 className='text-center'>No Data Found</h3>}
                        {couponlist.map((i) => {
                            console.log("i",i);
                            return (
                                <div className="row">
                                    <div className="col-md-3 col-12 coupon-code">
                                        <div className="single-coupon-thumb" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {/* <img src={coupon} alt="" className='img-responsive' /> */}
                                            <h4 style={{ color: "#5b8c51", fontWeight:"bold", borderBottom:"0" }}>{i.COUPEN_CODE}</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12" style={{marginTop:"5px"}}>
                                        <div style={{ marginBottom: "20px" }}>
                                            <h5>{i.COUPEN_CODE}</h5>
                                            <ul>
                                                <li>Coupon Type - <b>{i.FIXED_OR_PERCENTAGE}</b></li>
                                                <li>From Date - <b>{i.FROM_DATE}</b></li>
                                                <li>To Date - <b>{i.TO_DATE}</b></li>
                                                <li>{i.DESCRIPTION}</li>
                                                <li onClick={handleOpen} style={{fontWeight:"bold", color:"#5b8c51", cursor:"pointer"}}>Terms & Conditions</li>
                                            </ul>                                          
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12">
                                        <div className='applycoupon'>
                                            <a class="btn button-browse btn-lg btn-block" onClick={() => applyCoupon(i)} style={{background:"transparent"}} type="button" data-toggle="modal" data-target="#coupon-printable">APPLY COUPON</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
                </div>
                
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style1, width: 400 }} style={{ borderRadius: "5px", background: "white" }}>
                    <h1>Terms & Conditions</h1>
                    <h3 className='coupon-h3' style={{ fontSize: "18px", marginLeft: "5px", fontFamily: "sans-serif" }}>This Coupon Is Available On Birthday Month Only</h3>
                    <div className="row">
                        <div className="col-12" >
                            <input type="button" value="OK" className='hover1' onClick={handleClose} style={{ float: "right" }} />
                        </div>
                    </div>


                </Box>
            </Modal>
        </div>
    )
}

export default Coupon