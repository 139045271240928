import { ActionPanel } from "./ActonPanel";
import { FormPanel } from "./Formpanel";
import { Component, useContext, useState } from "react";
import './Login.css'
import { AuthContext } from "../AuthContext/AuthContext";
import { useEffect } from "react";


export const Login = () => {
  const { signIn, setSignIn } = useContext(AuthContext)
  const [transition, setTransition] = useState(false)


  console.log("signIn", signIn)
  //   this.state = {  
  //     transition: false
  //   }
  //   this.slide = this.slide.bind(this);
  // }
  function slide() {
    // const {  transition } = this.state;


    if (transition) {
      return;
    }

    const formPanel = document.querySelector('.FormPanel');
    const actionPanel = document.querySelector('.ActionPanel');
    const actionPanelChildren = actionPanel.children;


    const formBoundingRect = formPanel.getBoundingClientRect();
    const actionBoundingRect = actionPanel.getBoundingClientRect();

    formPanel.style.transition = 'all 0.7s cubic-bezier(.63,.39,.54,.91)';
    actionPanel.style.transition = 'all 0.7s cubic-bezier(.63,.39,.54,.91)';
    [...actionPanelChildren].forEach(child => child.style.transition = 'all 0.35s cubic-bezier(.63,.39,.54,.91)');

    setTransition(true)

    // this.setState({
    //   transition: true
    // })

    if (signIn) {
      formPanel.style.transform = `translateX(${actionBoundingRect.width}px)`;
      actionPanel.style.transform = `translateX(${-formBoundingRect.width}px)`;

      [...actionPanelChildren].forEach(child => {
        child.style.transform = `translateX(${actionBoundingRect.width / 2}px)`;
        child.style.opacity = 0;
        child.style.visibility = 'hidden';
      });

      formPanel.style.borderRadius = '0 20px 20px 0';
      actionPanel.style.borderRadius = '20px 0 0 20px';
    } else {
      formPanel.style.transform = `translateX(${-actionBoundingRect.width}px)`;
      actionPanel.style.transform = `translateX(${formBoundingRect.width}px)`;

      [...actionPanelChildren].forEach(child => {
        child.style.transform = `translateX(${-actionBoundingRect.width / 2}px)`;
        child.style.opacity = 0;
        child.style.visibility = 'hidden';
      });


      formPanel.style.borderRadius = '20px 0 0 20px';
      actionPanel.style.borderRadius = '0 20px 20px 0';

    }
    const timeoutState = setTimeout(() => {

      [...actionPanelChildren].forEach(child => {
        child.style.transition = 'none';
        child.style.transform = `translateX(${signIn ? (-actionBoundingRect.width / 3) : (actionBoundingRect.width / 3)}%)`;
      });

      // this.setState({
      //   signIn: !signIn
      // });

      setSignIn(!signIn)

      clearTimeout(timeoutState);
    }, 350);

    const timeoutChildren = setTimeout(() => {
      [...actionPanelChildren].forEach(child => {
        child.style.transition = 'all 0.35s cubic-bezier(.63,.39,.54,.91)';
        child.style.transform = `translateX(0)`;
        child.style.opacity = 1;
        child.style.visibility = 'visible';
      });
      clearTimeout(timeoutChildren);
    }, 400);

    const timeoutTransition = setTimeout(() => {
      formPanel.style.transition = 'none';
      actionPanel.style.transition = 'none';
      formPanel.style.transform = 'translate(0)';
      actionPanel.style.transform = 'translate(0)';
      actionPanel.style.order = signIn ? -1 : 1;

      // this.setState({
      //   transition: false
      // })


      setTransition(false)
      clearTimeout(timeoutTransition);
    }, 700);

  }

  return (
    <div className="main-div">
      <div className="App2 main-app">
        <FormPanel signIn={signIn} />
        <ActionPanel signIn={signIn} slide={slide} />
      </div>
    </div>
  );
}