import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Base_Url } from "../base_url/Base_Url";

export const FormPanel = ({ signIn }) => {
  const [mobile1, setMobile1] = useState("")
  const [mobile2, setMobile2] = useState("")
  const [FullName1, setFullName1] = useState("")
  const [FullName2, setFullName2] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

    // heading
    const heading = signIn ? 'Sign in' : 'Create account';
  
    const mobileNumber = (e) => {
      const value = e.target.value
      if (!Number(value)) {
          return setMobile1("")
      } else if (value.length === 11) {
          return;
      }


      setMobile1(value)
  }

  
  const getOtp1 = () => {
    if (!FullName1 || !mobile1) {
        return alert("all Field is Mondatory")
    }else{
        setLoading(true)
    axios.post(`${Base_Url}/OldUser`,
    {
        "MOBILE_NO": `${mobile1}`,
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": ""
      }
    ).then((res) => {
        console.log(res.data);
        const newData = res.data
        console.log(newData.DATA[0].STATUS);
        if (newData.DATA[0].STATUS === "Already Register") {
            return axios.post(`${Base_Url}/OTP_Mobile`,
            {
                "MOBILE_NO": `${mobile1}`
            }
        ).then((res) => {
            console.log(res.data);
            const data = res.data
            setLoading(false)
            navigate('/signinotp', { state: {data, mobile1, FullName1} })

        })
        }
        else {
            return alert("Please Sign Up First")
                // navigate('/signup')
        }
    })
}

}

const mobileNumber2 = (e) => {
  let value = e.target.value
  if (!Number(value)) {
      return setMobile2("");
  }
  else if (value.length === 11) {
      return;
  }

  setMobile2(e.target.value || " ")

}




    const inputs = [
      {
        type: 'text',
        placeholder: 'Full Name'
      },
      {
        type: 'text',
        placeholder: 'Mobile'
      }
    ];
    // if the signIn boolean directs toward the sign up form, include an additional input in the inputs array, for the name
    if (!signIn) {
      inputs.unshift({
        type: 'text',
        placeholder: 'Name'
      });
    }

    const getOtp2 = () => {
      const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!FullName2 || !email || !mobile2) {
          return alert("Please all field is mondatory")
      } else if (!pattern.test(email)) {
          return alert("Please Enter Valid Email")
      }else{
          setLoading(true)
      axios.post(`${Base_Url}/OldUser`,
      {
          "MOBILE_NO": `${mobile2}`,
          "EXTRA1": "",
          "EXTRA2": "",
          "EXTRA3": "",
          "LANG_ID": ""
        }
      ).then((res) => {
          console.log(res.data);
          const newData = res.data
          console.log(newData.DATA[0].STATUS);
          if (newData.DATA[0].STATUS === "Already Register") {
              return alert("Already Register")
          } else {
              axios.post(`${Base_Url}/OTP_Mobile`,
                  {
                      "MOBILE_NO": `${mobile2}`
                  }
              ).then((res) => {
                  console.log(res.data);
                  const data = res.data
                  setLoading(false)
                  navigate('/signupotp',{state:{data, mobile2, FullName2, email}})
              })
          }
          

      })

     
  }

  }
  
    // link also shared by both versions of the panel
    const link = {
      href: '#',
      text: 'Forgot your password?'
    }
  
    const button = signIn ? 'Sign in' : 'Sign up';
  
    // render the specified content in the matching elements
    return (
      <div className="Panel FormPanel">
        <h2 style={{marginTop:"30px"}}>{heading}</h2>
        {/* <div className="Social">
          {
            social.map(({ href, icon }) => <a href={href} key={icon}>{icon}</a>)
          }
        </div> */}
        {/* <p>{paragraph}</p> */}
        <form>
          {signIn &&
          <>
          <input type="text" placeholder="Full Name" value={FullName1} onChange={(e) => setFullName1(e.target.value)}/>
          <input type="text" placeholder="Mobile" value={mobile1} onChange={(e) => mobileNumber(e)}/>
          </>
          }
         {!signIn &&
         <>
         <input type="text" placeholder="Full Name" value={FullName2} onChange={(e) => setFullName2(e.target.value)} />
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input type="text" placeholder="Mobile" onChange={(e) => mobileNumber2(e)} value={mobile2}/>
          </>
          }

          {/* {
            inputs.map(({ type, placeholder }) => <input type={type} key={placeholder} placeholder={placeholder} />)
          } */}
        </form>
        {/* <a href={link.href}>{link.text}</a> */}
        {signIn && <button style={{cursor:"pointer"}} onClick={() => getOtp1()}>Get OTP</button>}
        {!signIn &&<button style={{cursor:"pointer"}} onClick={() => getOtp2()}>Get OTP</button>}
      </div>
    );
  };
  