import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Base_Url } from '../base_url/Base_Url'
import ReactPlayer from 'react-player'



const Video = () => {
  const [video, setVideo] = useState([])

  useEffect(() => {
    axios.post(`${Base_Url}/Get_Data`,
      { "START": 0, "END": 10000, "WORD": "", "GET_DATA": "Get_VideoList_ALL", "ID1": "94", "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": "" }
    ).then((res) => {
      console.log(res.data);
      setVideo(res.data.DATA)
    })
  }, [])
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="breadcrumb bg-light" style={{ background: "transparent" }}>
              <div className="two">
                <h1 className='cat_name' style={{ marginTop: "0" }}>Video
                  <span></span>
                </h1>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row" >

          {video.map((i) => {
            return (
              <div className="col-lg-6">
                <div className='video'>
                <ReactPlayer url={`www.youtube.com/watch?v=${i.VIDEO_URL}`} controls />
                  {/* <video src={`www.youtube.com/watch?v=${i.VIDEO_URL}`} width="400" height="300" controls /> */}
              </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Video