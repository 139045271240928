// import Signin from "./Signin";

export const ActionPanel = ({ signIn, slide }) => {
    // content to show conditional to the signIn boolean
    const heading = signIn ? 'Welcome back!' : 'Hello friend!'  ;
    const paragraph = signIn ? 'To keep connected with us please login with your personal info' : 'Enter your personal details and start your journey with us' ;
    const button = signIn ? 'Sign up!' : 'Sign in!';
  

    const styles = {
      order1:{
        order:"1"
      },
      order2:{
        order:"-1"
      }
    }
    console.log("Signin",signIn)

    // render the elements and includ the slide function when pressing the button
    return (
      <div className="Panel ActionPanel" style={signIn ? styles.order1 : styles.order2}>
        <h2 style={{marginTop:"-20px", color:"#ffffff"}}>{heading}</h2>
        <p>{paragraph}</p>
        <button onClick={slide}>{button}</button>
      </div>
    );
  };