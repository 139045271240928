import React from 'react'
import wp from '../image/wp.png'
import logo from '../image/logo.png'
import { Navigate, useNavigate } from 'react-router-dom'
import logo2 from '../image/Be-leaf.png'


const data = localStorage.getItem("user_id")

const Footer = () => {
    const navigate = useNavigate()
    return (
        <div>
            <footer className="footer-section" style={{ background: "#31512a" }}>
                <div className="container">
                    <div className="footer-cta pt-512 pb-512" style={{display:"none"}}>
                        <div className="row">
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta">
                                    <i className="fas fa-map-marker-alt"></i>
                                    <div className="cta-text">
                                        <h4 style={{ color: "#ffffff" }}>Find us</h4>
                                        <span><a href="https://goo.gl/maps/m3BKfJ6s8UNXFDSq5" className="hover-address" target='_blank'>1010 Avenue, sw 54321, chandigarh</a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta">
                                    <i className="fas fa-phone"></i>
                                    <div className="cta-text">
                                        < h4 style={{ color: "#ffffff" }}>Call us</h4>
                                        <span><a href="tel:8459227864">+91 8459227864</a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta">
                                    <i className="far fa-envelope-open"></i>
                                    <div className="cta-text">
                                        <h4 style={{ color: "#ffffff" }}>Mail us</h4>
                                        <span><a href="mailto:beleafnsk@gmail.com">beleafnsk@gmail.com</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content pt-512 pb-512">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 mb-50">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <a ><img src={logo2} className="img-fluid" alt="logo" /></a>
                                    </div>
                                    <div className="footer-text">
                                        <p>This website is operated by Be-leaf inc. offers this website, including all information, tools and services available from this site to you. the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                                    </div>
                                    {/* <div className="footer-social-icon">
                                <span>Follow us</span>
                                <a href="#"><i className="fab fa-facebook-f facebook-bg"></i></a>
                                <a href="#"><i className="fab fa-twitter twitter-bg"></i></a>
                                <a href="#"><i className="fab fa-google-plus-g google-bg"></i></a>
                            </div> */}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-30 ">
                                <div className="footer-widget ">
                                    <div className="footer-widget-heading">
                                        <h2 className='Links' style={{ color: "#ffffff", fontSize:"20px" }}>Useful Links</h2>
                                    </div>
                                    {data ? (
                                        <ul className='newcss'>
                                        <li><a className='hover-class' onClick={() => navigate('/')}>Home</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/video')}>Video</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/refund-policy')}>Refund Policy</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/terms-conditions')} href='#'>Terms & Conditions</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/privacy-policy')} href='#'>Privacy Policy</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/cart')}>Cart</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/wishlist')}>Wishlist</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/my-order')}>My Orders</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/reward-wallet')}>Reward Wallet</a></li>                                      
                                    </ul>
                                    ) : (
                                        <ul className='newcss'>
                                        <li><a className='hover-class' onClick={() => navigate('/')}>Home</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/video')}>Video</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/refund-policy')}>Refund Policy</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/terms-conditions')} href='#'>Terms & Conditions</a></li>
                                        <li><a className='hover-class' onClick={() => navigate('/privacy-policy')} href='#'>Privacy Policy</a></li>
                                    </ul>
                                    )}
                                    
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                                <h2 style={{fontSize:"21px", color:"#ffffff"}}>Contact</h2>
                                <address className="margin-bottom-40">
                                    <a href="https://goo.gl/maps/m3BKfJ6s8UNXFDSq5" className="hover-address" target='_blank'>
                                        BiG Strategic Advisors Group
                                        A 321, Lodha Supremus II, Beside Thane Passport Office, Road No 22, Wagle Estate, Thane west, 400604</a> <br />
                                    <span style={{color:"#abb7a7"}}> Phone: <a href="tel:8459227864" className="mail">  +91 8459227864</a></span><br />
                                    <span style={{color:"#abb7a7"}}> Email: <a href="mailto:beleafnsk@gmail.com" className="mail">beleafnsk@gmail.com</a><br /></span>

                                </address>
                            </div>
                        </div>
                    </div>
                </div>
                
            </footer>
            
            <a href="https://wa.me/8459227864" target='_blank' > <img src="assets/img/wp.png" alt="images" className="wp img-responsive" /></a>
        </div>
    )
}

export default Footer